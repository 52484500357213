import React from 'react'

import styles from './hero.module.css'

const Hero = (props) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.kicker}>
        {props.data}
      </div>
    </div>
  </div>
)

export default Hero
