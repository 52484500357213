import {Link} from 'gatsby'
import React from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './project-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function ProjectPreview (props) {
  function getCategories () {
    switch (props.categories.length) {
      case 1:
        return (props.categories.map(category => (
          <span key={category._id}>{category.title}</span>
        )))
      case 2:
        return (
          props.categories.map((category, i) => {
            if (props.categories.length === i + 1) {
              return <span key={category._id}>{category.title}</span>
            } else {
              return <span key={category._id}>{category.title} + </span>
            }
          })
        )
      default:
        return null
    }
  }

  return (
    <a className={styles.root} href={props.projectUrl} target='_blank'>
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      <div className={styles.info}>
        {getCategories()}
        <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
      </div>
    </a>
  )
}

export default ProjectPreview
